@use '@reply-pro/component-library/abstracts' as *;

.endpoint-modal {
  $modalWidth: 900px;
  $inputOutputPadding: $element-padding-xxl;

  width: $modalWidth;

  .endpoint-modal-body {
    border-top: $border-skinny-default;
    border-bottom: $border-skinny-default;
  }
  
  .endpoint-modal-body-wrapper {
    position: relative;
    display: flex;
    border-radius: $border-radius-md;
    border: $border-skinny-default;
    
    .endpoint-modal-input {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: $spacing-small-lg;
      padding: $element-padding-lg;
      padding-right: $inputOutputPadding;
    }
    
    
    .endpoint-modal-output {
      padding-block: $element-padding-md;
      padding-right: $element-padding-md;
      padding-left: $inputOutputPadding;
      height: 100%;
      
      pre {
        height: 100%;
        max-height: 450px;
        font-size: 10px;
        overflow: auto; 
        white-space: pre-wrap;
        word-wrap: break-word; 
        border: $border-skinny-default;
        border-radius: $border-radius-sm;
        background-color: $offWhite;
        padding: $element-padding-md;
        
        .endpoint-modal-output-description {
          display: inline;
          
          .endpoint-modal-output-description-segment {
            display: inline;
            word-break: break-word;
          }
          
          svg {
            display: inline;
            width: 10px;
            vertical-align: middle;
            margin: 0 2px;
          }
        }
      }
    }

    .endpoint-modal-run-button {
      position: absolute;
      padding: $element-padding-sm;
      border-radius: $border-radius-circle;
      margin: auto;
      
      svg {
        color: $gray;
        flex-basis: 18px;
        width: 18px;
        height: 18px;
        margin: 0;
        display: inline-block;
      }

      .reload-icon {
        animation: pulseRotate 1s infinite;
      }
      
      &:hover :not(&:disabled) {
        svg {
          color: $main;
        }
      }

      &.adjusting-position {
        opacity: 0;
        transition: opacity 200ms;
      }
    }
  }
}

@keyframes pulseRotate {
  100% {
    transform: rotate(.5turn)
  }
}