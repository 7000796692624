@use '@reply-pro/component-library/abstracts' as *;

.email-page-container {
    border-top: $border-skinny-default;
    padding: $element-padding-xl;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 75vh;
    overflow-y: auto;
    gap: $spacing-small-xl;

    .email-branding-nav-folder,
    .email-branding-preview {
        width: 50%;
    }

    .email-branding-nav-folder {
        height: 100%;
        overflow: hidden;

        .email-branding-options {
            max-height: calc(100vh - 370px);
            overflow-y: auto;

            .email-branding-option {
                .email-branding-container {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .email-branding-details {
                        display: flex;
                        flex-direction: column;
                        gap: $spacing-small-xs;
                        min-width: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-right: $element-margin-md;

                        .label {
                            @include heading-petite;
                            text-transform: uppercase;
                        }

                        .value {
                            @include text-xs-reg;
                        }
                    }

                    .email-branding-actions {
                        .edit {
                            width: 18px;
                            cursor: pointer;
                        }

                        color: $gray;
                    }
                }

                &:hover {
                    .email-branding-container {
                        .email-branding-actions {
                            .edit {
                            color: $mainLight;
                            }
                        }
                    } 
                }
            }
        }
    }

    .email-branding-preview {
        border-radius: $border-radius-lg;
        border: $border-skinny-default;
        overflow-y: auto;
    }
}