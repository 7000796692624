.add-users-form-container {
  display: grid;
  grid-template-columns: repeat(auto);
  grid-template-rows: repeat(auto);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  }
  .add-users-container { grid-area: 1 / 1 / 2 / 5; }
  .change-role-container { grid-area: 2 / 1 / 3 / 5; }
  
.add-users-container{
  padding: 0rem 2rem;
  .add-user-select-input{
    width: 100%;
  }
}
.single-user-select {
  width: 100%;
  display: flex;
  align-items: center;
  span {
    width: 100%;
  }
}
.change-role-container{
  max-height: 40vh;
  overflow-y: auto;
  
  .user-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .user-wrap{
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-around;
      padding: 0rem 2rem 0rem 2rem;
      gap: 1rem;
      .delete-button{
        width: 14px;
        
        &:hover{
          color: red;
        }
      }
      .user{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0rem 0rem 0rem 1rem ;
        .user-name{
          font-weight: bold;
          &::first-letter{
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
