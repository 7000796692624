@use '@reply-pro/component-library/abstracts' as *;

.feature-list {
    .feature-nav {
        padding: $element-padding-md;
        .single-feature {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .feature-name {
                @include heading-petite;
                text-transform: uppercase;
            }
            .feature-actions {
                display: flex;
                align-items: center;
                gap: $spacing-small-sm;
                .edit-maintenance {
                    width: 18px;
                    color: $gray;
                    cursor: pointer;

                    &.disabled {
                        color: $lighterGray;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

.maintenance-modal-header {
    min-width: 100% !important;
}
.maintenance-modal, .maintenance-modal-body {
    width: 480px;
    .maintenance-inputs {
        display: flex;
        flex-direction: column;
        gap: $spacing-small-sm;
        .maintenance-input-container {
            display: flex;
            flex-direction: column;
            gap: $spacing-small-xxs;
            .maintenance-input {
                width: 100%;
                textarea {
                    resize: none;
                }
            }
        }
    }
}
