@use 'styles/abstracts' as *;

* {

  $scrollbarColor: $lightGray;
  $scrollbarTrackColor: $lightestGray;

  scrollbar-width: thin;
  scrollbar-color: $scrollbarColor $scrollbarTrackColor;

  &::-webkit-scrollbar {
    width: 8px;
    height: 4px;
    :hover {
      background-color: transparent;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: $scrollbarColor;
    border-radius: 6px;
    border: 1px solid $scrollbarTrackColor;

    &:hover {
      border: 0px;
      background-color: transparent;
    }
  }
}