@use '@reply-pro/component-library/abstracts' as *;

.report-collections-list {
  max-height: calc(100vh - 200px);
  flex-grow: 1;
  overflow-y: auto;
}

.collection-nav-item {
  position: relative;

  &:first-child {
    a {
      border-top: $border-null !important;
    }
  }

  .collection-name {
    max-width: 95%;
    flex-grow: 1;
    word-break: break-word;
    white-space: normal;
  }

  .collection-options {
    z-index: 3;
    right: 1%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 1.1rem;
    opacity: 0;
    cursor: pointer;

    .icon {
      color: $dark;
      width: 1.1rem;
    }
  }

  &:focus {
    .collection-options {
      opacity: 1;
    }
  }

  &:hover {
    .collection-options {
      opacity: 1;
      color: $white;
    }
  }
}
