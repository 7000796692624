@use '@reply-pro/component-library/abstracts' as *;

.left-nav {
  padding: $element-padding-xxs $element-padding-sm;
  width: 233px !important;
  overflow-y: auto;

  .nav-folder {
    margin-top: 15px;
  }
}
