@use "styles/abstracts" as *;

.surveys-list {
  display: flex;
  flex-direction: column;
  height: 70vh;
  overflow-y: auto;

  .surveys-container-center {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray;
  }

  .survey-container {
    display: flex;
    align-items: center;
    cursor: default;
    padding: $spacing-small-md $spacing-small-xl;

    .survey {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100vw;

      .name-and-info-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .name {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
        }
      }

      .survey-list-actions-container {
        display: flex;
        align-items: center;
        gap: $spacing-small-lg;

        .survey-action {
          width: 18px;
          cursor: pointer;
        }
      }
    }

    &:first-child {
      border-top: none;
    }
  }
}