@use '@reply-pro/component-library/abstracts' as *;

.sub-header-title {
  padding: $padding-null $element-padding-xs;
  @include heading-petite;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.title-setup-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.survey-creator-container {
  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.survey-creator-empty-state {
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  @include text-sm-reg;
  color: $gray;
}

.saving-changes-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  flex-direction: column;
  gap: $spacing-small-xs;
}
