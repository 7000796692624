@use '@reply-pro/component-library/abstracts' as *;

.qr-modal {
    min-width: 400px;
    .modal-header, .modal-body {
        min-width: 100%;
        max-width: 100%;
    }
    .qr-code-card-container {
        display: flex;
        justify-content: center;
        .qr-code-card {
            display: flex;
            justify-content: center;
            align-items: center;
            border: $border-skinny-default;
            border-radius: $border-radius-sm;
            .qr-code {
                padding: $element-padding-lg;
                max-width: 200px;
                max-height: 200px;
            }
        }
    }
    .download-button {
        display: flex;
        justify-content:space-between;
        align-items: center;
        width: 100%;
        .button {
            display: flex;
            flex-direction: row;
            gap: $spacing-small-xxs;
            svg{
                padding: 0;
                margin: 0;
            }
        }
    }
}