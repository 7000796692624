@use '@reply-pro/component-library/abstracts' as *;


.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100px;
  border-top: $border-skinny-default;
  border-bottom: $border-skinny-default;
}