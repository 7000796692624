@use 'styles/abstracts' as *;

.app-header {
  display: flex;
  align-items: center;
  padding: $padding-null $padding-null;
  max-height: 70px;

  .context-header {
    flex-grow: 1;
  }
}