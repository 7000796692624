@use '@reply-pro/component-library/abstracts' as *;

.manage-user-groups-button {
  white-space: nowrap;
}

.manage-user-groups-modal {
  overflow: hidden;
  width: 60vw;

  .modal-header {
    min-width: 0;
  }

  .modal-body {
    height: 65vh;
    padding: $padding-null;
  }

  .manage-user-groups-modal-body {
    height: 100%;
    display: flex;
    flex-direction: column;

    .manage-user-groups-search-bar-container {
      padding: $element-padding-md;
      border-bottom: $border-skinny-default;
    }
    
    .manage-user-groups-user-groups-container {
      overflow-y: auto;
      flex-grow: 1;
      min-height: 0;
  
      .loading-spinner {
        display: grid;
        place-content: center;
        height: 100%;
      }
  
      .manage-user-groups-user-group-nav-item {
        padding: $element-padding-lg;
        cursor: default;
  
        &:first-of-type {
          border-top: $border-null;
        }
  
        .manage-user-groups-user-group {
          display: flex;
          flex-grow: 1;
          gap: calc(2 * $spacing-small-xl);
          
          .manage-user-groups-user-group-information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: $spacing-small-xxs;
            flex-grow: 1;
  
            .manage-user-groups-user-group-name {
              @include heading-petite;
              text-transform: uppercase;
            }
  
            .manage-user-groups-user-group-description {
              @include text-xs-reg;
            }
          }
        }
      }
    }
  }
}