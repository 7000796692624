@use '@reply-pro/component-library/abstracts' as *;

.survey-setup-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: $spacing-small-xs;
    .save-timestamp {
        @include text-xs-reg;
        color: $gray;
    }
    .gray-icon {
        color: $gray;
    }
}
