@use '@reply-pro/component-library/abstracts' as *;

.edit-in-app-branding-modal {
  width: 700px;

  h3 {
    @include text-sm-bold;
  }
  
  .in-app-brand-container-columns {
    display: flex;
    gap: $spacing-small-lg;
  }

  .in-app-brand-container-rows {
    flex-grow: 1.5;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    gap: $spacing-small-sm;
  }
  
  .in-app-brand-container-center {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: $border-skinny-default;
    border-radius: $border-radius-lg;
    padding: $element-padding-lg;
    padding-right: $element-padding-xs;
    min-height: 123px;
    margin-block: auto;

  }

  .helper-text {
    @include text-xs-reg;
    color: $gray;
  }

  label:has(textarea) {
    flex-grow: 1;
  }

  textarea {
    resize: none;
  }

  .in-app-brand-link-text {
    @include text-md-bold;
    text-align: center;
    text-wrap: balance;

    span {
      color: $main;
    }
  }

  .in-app-brand-signature-text {
    @include text-md-reg;
    color: $gray;
    flex-grow: 1;
    white-space: pre-wrap;
    word-break: break-all;
    max-height: 300px;
    overflow-y: auto;
    padding-right: $element-padding-sm;
  }

  .novu-identifier-help-text {
    color: $mainLight;
    cursor: pointer;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }

  .edit-in-app-branding-modal-action-container {
    display: flex;
    flex-direction: column;
    gap: $spacing-small-sm;

    .edit-in-app-branding-modal-action {
      margin-left: auto;
    }

    .edit-in-app-branding-modal-error-message {
      @include text-xs-reg;
      color: $error;
    }
  }
}