@use 'styles/abstracts' as *;

.user-group-user {
  display: flex;
  cursor: default !important;

  .user-group-user-info {
    display: flex;
    flex-direction: column;
    gap: $spacing-small-xs;

    .user-group-user-name {
      @include heading-petite;
      text-transform: uppercase;
    }
  }

  .user-group-user-remove {
    margin-left: auto;
    visibility: hidden;
  }
  
  &:hover {
    .user-group-user-remove {
      visibility: visible;
    }
  }
}