@use 'styles/abstracts' as *;

$modal-height: 60vh;
.modal-with-filter-buttons {
  .modal-header,
  .modal-body {
    width: 900px;
  }

  .modal-body {
    width: 100%;
    padding: 0;
    padding-bottom: 0.25rem;
  }
  .loading-relation-interactions {
    display: flex;
    align-content: center;
    justify-content: center;
    height: $modal-height;
    div {
      position: absolute;
      top: 35%;
      color: $gray;
    }
  }
  .filter-buttons {
    width: 100%;
    .filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .filter-select-buttons {
        display: flex;
        flex-direction: row;
        padding: $padding-small-md;
        gap: $spacing-small-md;
      }
      .toggle-and-other-filters {
        display: flex;
        flex-direction: row;
        justify-self: flex-end;
        gap: $spacing-small-md;
        .toggle {
          display: flex;
          align-items: center;
          gap: $spacing-small-md;
        }
      }
    }
    .line-1 {
      display: flex;
      z-index: 100;
      bottom: 100%;
      height: 1px;
      background-color: $lightestGray;
    }
    .line-2 {
      display: flex;
      z-index: 100;
      top: 100%;
      border-width: 0.1px;
      height: 1px;
      background-color: $lightestGray;
    }

    &.is-mobile {
      .filters {
        padding-right: $padding-small-xs;
        padding-left: $padding-small-xs;
        .filter-select-buttons {
          padding: $padding-small-sm $padding-small-xs $padding-small-sm $padding-small-xs;
          gap: $spacing-small-sm;
          .select-button {
            padding: $padding-small-xs;
          }
        }
        .toggle-and-other-filters {
          padding-left: 0;
          .toggle {
            padding-right: $padding-small-xs;
            gap: $spacing-small-xs;
          }
        }
      }
    }
    .other-filters-mobile {
      padding: $padding-small-xs $padding-small-md $padding-small-xs $padding-small-md;
    }
  }
  .children-list {
    height: $modal-height;
    overflow-y: auto;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
}

.relation-icon {
  width: 24px;
}

@media screen and (max-width: 380px) {
  .modal-with-filter-buttons {
    .filter-buttons {
      &.is-mobile {
        .filters {
          padding-right: $padding-small-xs;
          padding-left: $padding-small-xs;
          .filter-select-buttons {
            padding: $padding-small-sm $padding-small-xs $padding-small-sm $padding-small-xs;
            gap: $spacing-small-xs;
            .select-button {
              padding: $padding-small-xs;
            }
          }
          .toggle-and-other-filters {
            .toggle {
              padding-right: $padding-small-xs;
              gap: $spacing-small-xs;
            }
          }
        }
      }
      .other-filters-mobile {
        padding: $padding-small-xs $padding-small-md $padding-small-xs $padding-small-md;
      }
    }
  }
}

@media screen and (max-width: 340px) {
  .modal-with-filter-buttons {
    .filter-buttons {
      &.is-mobile {
        .filters {
          padding-right: $padding-small-xxs;
          padding-left: $padding-small-xxs;
          .filter-select-buttons {
            padding: $padding-small-sm $padding-small-xxs $padding-small-sm $padding-null;
            gap: $spacing-small-xs;
            .select-button {
              padding: $padding-small-xxs;
            }
          }
          .toggle-and-other-filters {
            .toggle {
              padding-right: $padding-null;
              gap: $spacing-small-xxs;
            }
          }
        }
      }
      .other-filters-mobile {
        padding: $padding-small-xs $padding-small-md $padding-small-xs $padding-small-md;
      }
    }
  }
}
