@use '@reply-pro/component-library/abstracts' as *;

.distribution-modal-body {
    display: flex;
    flex-direction: column;
    gap: $spacing-small-sm;
    .distribution-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $spacing-small-xs;
        padding: $element-padding-xs;
        border: $border-skinny-default;
        border-radius: $border-radius-sm;
        max-height: 40vh;
        overflow-y: auto;
        .location-code {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: $padding-null $element-padding-md;
            .location-name {
                @include text-sm-bold;
            }
            .survey-code-actions {
                display: flex;
                align-items: center;
                padding: $element-padding-xs $element-padding-sm;
                gap: $spacing-small-sm;
                color: $gray;
                .redirect-survey-link {
                    color: $gray;
                    display: flex;
                    padding: $padding-null;
                    .redirect-to-web-icon{
                      width: 18px;
                      cursor: pointer;
                      color: $gray;
                      &:hover {
                        color: $mainLight
                    }
                    }
                }
                .code {
                    width: 48px;
                    cursor: pointer;
                    &:hover {
                        color: $mainLight
                    }
                }
                .copy {
                    color: $success;
                    &:hover {
                        color: $success;
                    }
                }
                svg {
                    width: 18px;
                    cursor: pointer;
                    &:hover {
                        color: $mainLight
                    }
                }
            }
        }
    }
}

.loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
}