@use '@reply-pro/component-library/abstracts' as *;

.report-grid-panel {
  padding: $element-margin-xs $element-margin-sm;
  &:hover {
    color: $dark;
    transition: 0.2s;
  }
}

.report-actions {
  display: flex;
  flex-direction: row;
  gap: $spacing-small-lg;
  align-items: center;
  .action-icon, .trash-icon {
    width: 18px;
    color: $gray;
    transition: 0.2s;
    cursor: pointer;
    align-self: center;
    display: flex;
    align-items: center;
    &:hover {
      color: $dark;
      transition: 0.2s;
    }
  }
}
