@use 'styles/abstracts' as *;

.remove-user-group-modal {
  .modal-header, .modal-body {
    width: 500px !important;
    min-width: 0 !important;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $padding-small-xl;

    .remove-user-group-warning-icon {
      width: 50px;
      color: $orange;
      margin-bottom: $margin-small-xxs;
    }
    
    p:first-of-type {
      @include text-md-med;
      text-align: center;
      margin-bottom: $margin-small-xxs;
    }

    p:last-of-type {
      @include text-xs-reg;
      text-align: center;
    }
  }

  .remove-user-group-actions {
    display: flex;
    flex-direction: column;
    text-align: right;
    gap: $spacing-small-xxs;

    .error-message {
      @include text-xs-reg;
      color: $red2;
    }
  }
}