@use 'styles/abstracts' as *;

.add-new-modal, .add-new-modal-header, .add-new-modal-body {
  min-width: 400px !important;
  width: 400px;
  max-width: 400px;
  overflow: hidden;
}

.add-new-modal-content {
  display: flex;
  flex-direction: column;
  gap: $spacing-small-sm;
  width: 100%;
  overflow: hidden;
  span {
    @include text-xs-reg;
    color: $gray;
  }
  .add-new-modal-input {
    width: 100%;
  }
}