@use "styles/abstracts" as *;

.add-user-form {
  display: grid;
  grid-template-columns: repeat(auto);
  grid-template-rows: repeat(auto);
  grid-column-gap: $spacing-small-md;
  grid-row-gap: $spacing-small-md;
  padding: $padding-null $padding-small-xl $padding-null $padding-small-xl;
}

.account-set-features {
  .modal-body {
    display: flex;
    align-items: center;
    gap: $spacing-small-md;
    padding-block: $spacing-small-lg;

    p {
      @include text-sm-med;
      text-align: center;
      width: 60%;

      &:last-of-type {
        text-wrap: balance;
        @include text-xs-reg;
        color: $gray;
      }
    }
  }
}


