@import '@reply-pro/component-library/dist/style.css';

@font-face {
  font-family: 'ProximaSoft';
  src:
    url('@reply-pro/component-library/abstracts/Type/proximaSoft/ProximaSoft-Regular.woff') format('woff'),
    url('@reply-pro/component-library/abstracts/Type/proximaSoft/ProximaSoft-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaSoft';
  src:
    url('@reply-pro/component-library/abstracts/Type/proximaSoft/ProximaSoft-Medium.woff') format('woff'),
    url('@reply-pro/component-library/abstracts/Type/proximaSoft/ProximaSoft-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaSoft';
  src:
    url('@reply-pro/component-library/abstracts/Type/proximaSoft/ProximaSoft-Bold.woff') format('woff'),
    url('@reply-pro/component-library/abstracts/Type/proximaSoft/ProximaSoft-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

html,
body {
  background: #f9fafc;
  color: #343434;
}

body {
  margin: 0;
  font-family: 'ProximaSoft';
}

p {
  margin: 0;
}
