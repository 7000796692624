@use 'styles/abstracts' as *;

  .selected-users {
    height: 200px;
    margin: $margin-small-xxs;
    border-bottom: 1px solid $lightestGray;

    .user-list {
      height: 100%;
      overflow-y: auto;

      .user-user-group {
        display: flex;
        width: 100%;
        gap: $spacing-small-md;
        border-bottom: 1px solid $lightestGray;
        padding: $padding-small-md;
        justify-content: space-between;
        
        span {
          font-size: 14px;
          width: 45%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          min-width: 0;
        }
        
        &:last-child {
          border: none;
        }

        svg {
          width: 16px;
          cursor: pointer;
          color: $gray;
  
          &:hover {
            color: $error;
          }
        }
      }
    }

  }


