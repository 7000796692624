@use '@reply-pro/component-library/abstracts' as *;

.saving-changes-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.48);
    backdrop-filter: blur(2.8px);
    -webkit-backdrop-filter: blur(2.8px);
    flex-direction: column;
    gap: $spacing-small-xs;
}
