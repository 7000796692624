@use '@reply-pro/component-library/abstracts' as *;

.select-button {
  display: flex;
  justify-content: center;
  color: $gray !important;
  background-color: $lightestGray !important;
  border-radius: $border-radius-lg !important;

  &.experimental {
    width: 11rem;
  }

  .select-button-children {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: $spacing-small-xs;
    .experimental-button {
      width: 15px;
      margin-right: 0px !important;
    }
  }

  &.active {
    border-color: $main !important;
    color: $main !important;
    background-color: $offBlue !important;
  }
}
