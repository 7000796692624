@use 'styles/abstracts' as *;

.add-users-to-user-group-button {
  white-space: nowrap;
}

.add-users-modal-body {
  padding: $padding-small-sm;
  
  .add-users-user-selector {
    width: 100%
  }
}
