@use "styles/abstracts" as *;

.users-container {
  display: flex;
  flex-direction: column;
  height: 70vh;
  overflow-y: auto;

  .users-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user-container {
    display: flex;
    align-items: center;
    cursor: default;
    padding: $spacing-small-md $spacing-small-xl;

    .user {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100vw;

      .name-and-email-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .name {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
        }
      }

      .user-actions {
        display: flex;
        align-items: center;
        gap: $spacing-small-lg;

        .user-permissions,
        .user-password {
          width: 18px;
          cursor: pointer;
        }
        & .disabled {
          color: $lighterGray;
          pointer-events: none;

        }

        .user-type {
          font-size: 10px;
          font-weight: 500;
        }
      }
    }

    &:first-child {
      border-top: none;
    }
  }
}

.buttons-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}