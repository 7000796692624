.add-user-form {
  display: grid;
  grid-template-columns: repeat(auto);
  grid-template-rows: repeat(auto);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  padding: 0rem 2rem 0rem 2rem;
  }
  
  .firstName-container { 
    grid-area: 1 / 1 / 2 / 3;
    display: flex;
   }
  .lastName-container { 
    grid-area: 1 / 3 / 2 / 5;
    display: flex;
   }
  .email-container { 
    grid-area: 2 / 1 / 3 / 5;
   }
  .type-container {
    grid-area: 3 / 1 / 3 / 5;
  }

  .input-container{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .title{
      font-size: 14px;
      font-weight: bold;
      .mandatory-sign{
        color: red;
      }
    }
    .select-input {
      width: 100%;
    }
  }

