@use 'styles/abstracts' as *;

.settings-nav{
.logout-nav-container {
    svg {
      color: $error;
      transition: 0.4s;
    }

    .logout-nav {
      color: $error;
      transition: 0.4s;

      &:hover {
        color: $white;
        background-color: $error;
        transition: 0.4s;

        svg {
          color: $white;
        }
      }
    }
  }
}

  .logout-actions {
    display: flex;
    gap: 0.4rem;
  
    .confirm-logout-btn{
        border: transparent 1px solid;
        color: $error;

        &:hover,&:focus {
            color: $error;
            border: $error 1px solid;
            transition: 0.4s;
        }
    }
  }