$margin-null: 0rem;         // 0px
$margin-small-xxs: 0.25rem; // 4px
$margin-small-xs: 0.5rem;   // 8px
$margin-small-sm: 0.75rem;  // 12px
$margin-small-md: 1rem;     // 16px
$margin-small-lg: 1.5rem;   // 24px
$margin-small-xl: 2rem;     // 32px
$margin-small-xxl: 3rem;    // 48px

$padding-null: 0em;         // 0px
$padding-small-xxs: 0.25em; // 4px
$padding-small-xs: 0.5em;   // 8px
$padding-small-sm: 0.75rem; // 12px
$padding-small-md: 1em;     // 16px
$padding-small-lg: 1.5em;   // 24px
$padding-small-xl: 2em;     // 32px
$padding-small-xxl: 3em;    // 48px
