@use 'styles/abstracts' as *;

.right-modal {
  background-color: $offWhite !important;
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding: 1.5rem;
  width: 35%;
  height: 100%;
  margin-left: 65%;
  display: flex;
  flex-direction: column;
  transform-origin: right;
  animation: slideInRight 0.5s cubic-bezier(0.23, 1, 0.32, 1) forwards;

  .modal-header {
    text-align: left;
    color: $gray;
    min-width: 0;
    padding-inline: 0;
    letter-spacing: 0.08em;
    max-width: 100%;
    button {
      border: 1px solid $borderLine1;
    }
  }

  .modal-body {
    min-width: 0;
    width: 100%;
    padding-inline: 0;
    box-shadow: 0 0 0;
    opacity: 0;
    animation: fadeIn 0.5s cubic-bezier(0.23, 1, 0.32, 1) forwards 0.2s;
    flex-grow: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    transform-origin: right;
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}