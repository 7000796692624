@use '@reply-pro/component-library/abstracts' as *;


.add-post-survey-actions-modal {
    .add-post-survey-actions-container {
        display: flex;
        flex-direction: column;
        padding: $spacing-small-md;
        gap: $spacing-small-xs;
        border: $border-skinny-default;
        border-radius: $border-radius-sm;
        height: 280px;
        overflow: auto;
        @include text-xs-reg;

        .trigger-selectors, .action-manager-content {
            display: flex;
            align-items: center;
            width: 100%;
            gap: $spacing-small-xs;
            .input, .secondary-action-button {
                width: 100%;
                &.trigger {
                    width: 313px;
                }
            }
            .secondary-action-button{
                padding-top: 0.64rem;
                padding-bottom: 0.64rem;
                @include text-sm-reg;
                border: $border-skinny-default;

            }
        }
        .workflow-selector-container {
            width: 100%;
            .workflow-details{
                display: flex;
                gap: $spacing-small-xl;
                justify-content: space-between;
                align-items: center;
                .user-selector-container {
                    .activity-user-selector {
                        max-width: 180px;
                    }
                }
                .days-selector-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: $spacing-small-xs;
                    .days-selector-input {
                        width: 48px;
                    }
                }
            }
            .loading-workflows {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 68px;
            }
        }
    }
}
