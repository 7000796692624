@use '@reply-pro/component-library/abstracts' as *;

.report-list {
  padding: $element-padding-lg;
  margin: $margin-null $element-margin-lg $margin-null $element-margin-lg;
  border-top: $border-skinny-default;
  @include text-sm-reg;
}

.report-list-header {
  @include text-xs-bold;
  color: $gray;
}

.actions-header {
  display: flex;
  justify-content: flex-end;
}
