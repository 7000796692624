@use '@reply-pro/component-library/abstracts' as *;

.app-nav {
  flex-basis: 250px;
  display: flex;
  flex-direction: column;
  max-width: 250px;
  height: 100vh;
  color: $gray;
  .logo-container {
    padding: $element-padding-md $element-padding-md $padding-null $element-padding-sm;

    .logo {
      display: flex;
      width: 139px;
      height: 35px;
      margin-top: $element-margin-xs;
      margin-bottom: $element-margin-xs;
      cursor: pointer;
    }
  }
  .redirect-to-web {
    color: $gray;
    display: flex;
    gap: $spacing-small-xs;
    text-decoration-line: none;
    padding: $element-margin-sm;
    .redirect-to-web-icon{
      width: 18px;
      cursor: pointer;
      color: $gray;
    }
  }
  .context-nav {
    flex-grow: 1;
    overflow: hidden;
  }
  .navigation-bar{
    align-self: flex-start;
    margin: $element-margin-xs;

  }
}

@media screen and (max-height: 848px) {
  .app-nav {
      height: 88.4vh;
  }
}