@use '@reply-pro/component-library/abstracts' as *;

.search-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $spacing-small-md;

  .empty-state-svg {
    max-width: 240px;
  }
}
