@use '@reply-pro/component-library/abstracts' as *;

.endpoint-input-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $spacing-small-xs;

  .endpoint-input-field-title {
    @include text-sm-bold;
  }

  .endpoint-input-field-required {
    color: $error;
  }

  .endpoint-input-field-component {
    display: flex;
    cursor: pointer;
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    background-color: $white;
    padding: $element-padding-sm $element-padding-md;
    border: $border-skinny-default;
    border-radius: $border-radius-xs;
    transition: 0.2s;
    @include text-xs-reg;
  
    span {
      display: flex;
      gap: $spacing-small-xxs;
      color: $gray;
  
      svg {
        width: 14px;
        color: $gray;
      }
    }
  
    &:hover {
      background-color: $offWhite;
  
      span {
          color: $dark;
      }
  
      transition: 0.2s;
    }
  
    span {
      pointer-events: none;
    }
  
    input[type='file'] {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      font-size: 100px;
      cursor: pointer;
      opacity: 0;
    }
  
    &.success {
      border-color: $completed;
  
      span {
        svg {
            color: $completed;
        }
      }
    }
  }
}