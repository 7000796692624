@use '@reply-pro/component-library/abstracts' as *;

.loading-setup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 200px);
}

.details-setup-container {
  height: calc(100vh - 200px);
  overflow: auto;

  .details-input-container:has(>.detail-content) {
    display: flex;
    flex-direction: column;
    gap: $spacing-small-xs;

    .detail-title-container {
      padding: $element-padding-md $element-padding-lg $padding-null $element-padding-lg;
      display: flex;
      gap: $spacing-small-xs;
      @include text-sm-bold;
      justify-content: space-between;
      align-items: center;
      .locations-header {
        display: flex;
        align-items: center;
        .all-locations {
          padding-right: $element-padding-xs;
          @include text-xs-reg;
          color: $dark;
        }
      }
    }

    .detail-title {
      @include text-sm-bold;
    }
    .detail-content:not(:has(>.survey-styles-container)) {
      padding: $padding-null $element-padding-lg $element-padding-md $element-padding-lg;
      border-bottom: $border-skinny-default;
      display: flex;
      .container-with-description {
        display: flex;
        gap: $spacing-small-xs;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .description {
          @include text-xs-reg;
          padding: $padding-null $element-padding-md $padding-null $element-padding-md;
          text-align: center;
          color: $gray;
        }
      }
      &:last-child:has(> .save-button-container) {
          border-bottom: $border-null;
        }
    }
  }

  .details-input-container:not(:has(>.detail-content)) {
    display: flex;
    flex-direction: column;
    gap: $spacing-small-xs;

    .detail-title-container {
      padding: $element-padding-md $element-padding-lg $element-padding-md $element-padding-lg;
      display: flex;
      align-items: center;
      gap: $spacing-small-xs;
      @include text-sm-bold;
      line-height: 100%;
      justify-content: space-between;
      border-bottom: $border-skinny-default;
    }

    .detail-title {
      @include text-sm-bold;
      line-height: 100%;
    }
  }


  .survey-styles-container {
    border-top: $border-null;
    border-bottom: $border-skinny-default;

    button {
      padding: $element-padding-md $element-padding-lg;
      @include text-sm-bold;
      text-transform: none;
    }
  }

  .save-button-container {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
    padding: $element-padding-md $padding-null $padding-null $padding-null;
  }

  .privacy-policy-inputs,
  .details-input-container {
    .detail-input {
      width: 100%;
    }
  }

  .privacy-policy-inputs {
    display: flex;
    flex-direction: column;
    gap: $spacing-small-xs;
    width: 100%;
  }
}