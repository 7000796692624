@use '@reply-pro/component-library/abstracts' as *;

.location-permissions-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.location-permissions-aside-container {
  min-height: calc(100vh - 158px);
  max-height: calc(100vh - 158px);
  overflow: auto;
  .binary-input-container {
    padding: $element-padding-sm;
    border-bottom: $border-skinny-default;
  }
}

.right-details {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: $element-padding-lg;
  border-bottom: $border-skinny-default;
}

.empty-permission-group-viewer,
.no-location-permissions-container {
  min-height: 75vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: $spacing-small-lg;
  .astronaut-image {
    width: 70%;
  }
  .text {
    @include text-sm-reg;
    color: $gray;
  }
}

.permission-group-viewer {
  display: flex;
  width: 100%;
  max-height: calc(100vh - 220px);
  padding: $element-padding-lg;
  justify-content: space-between;
  gap: $spacing-small-lg;
  .users-nav {
    .user-list-container {
      height: 100%;
      overflow-y: auto;
      .single-user {
        flex-grow: 1;
        padding: $element-padding-lg;
        border-top: $border-skinny-default;
        @include heading-petite;
        color: $gray;
        text-transform: uppercase;
      }
    }
  }
  .locations-nav {
    flex-grow: 2;
    .locations-tree-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: $spacing-small-lg;
      padding: $element-padding-lg;
      border-top: $border-skinny-default;
      overflow-y: auto;
      .location-parents {
        display: flex;
        flex-direction: column;
        gap: $spacing-small-xs;
      }
    }
  }
}
