@use 'styles/abstracts' as *;

.user-group {
  .user-group-name {
    @include heading-petite;
    text-transform: uppercase;
  }

  .user-group-edit-icon {
    width: 24px;
    padding: $padding-small-xxs;
    border-radius: 50%;
    visibility: hidden;
    margin-left: auto;
    transition: color 200ms, background-color 200ms;
    margin-right: $margin-small-xxs;

    &:hover {
      background-color: $offWhite;
      color: $mainLight;
    }
  }
  
  .user-group-remove {
    visibility: hidden;
    width: 24px !important;
    height: 24px !important;
    padding: $padding-small-xxs;
    transition: color 200ms, background-color 200ms !important;
  }
  
  &.active {
    .user-group-edit-icon {
      visibility: visible;
    }

    .user-group-remove {
      visibility: visible;

      svg {
        color: $white !important;
      }

      &:hover {
        background-color: $offWhite;
        color: $mainLight;
      } 
    }
  }

  &:hover {
    .user-group-edit-icon {
      visibility: visible;
    }

    .user-group-remove {
      visibility: visible;
    }
  }
}