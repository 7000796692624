@use "styles/abstracts" as *;

  .input-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    .title{
      font-size: 14px;
      font-weight: bold;
      .mandatory-sign{
        color: red;
      }
    }
    .timeZone {
      width: 100%;
    }
    .features {
      width: 100%;
    }
  }
