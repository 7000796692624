@use 'styles/abstracts' as *;

.user-selector {
  display: flex;
  flex-direction: column;
  gap: $spacing-small-xxs;
}

ul {
  li {
    .user-option {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-block: $padding-small-lg !important;
      border-bottom: 1px solid $lightestGray;
      gap: $spacing-small-lg;

      .user-name, .user-attribute, .user-group-description {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        min-width: 0;
      }
      
      .user-name {
        width: 180px;
      }
      
      .user-attribute, .user-group-description {
        width: 260px;
        color: $gray;
        margin-left: auto;
      }

      .user-group-check-icon {
        visibility: hidden;

        &.show {
          visibility: visible;
        }
      }

      svg {
        color: $success;
      }
    }
  }
}
