@use '@reply-pro/component-library/abstracts' as *;

.create-edit-report-modal {
  display: flex;
  flex-direction: column;
  gap: $spacing-small-lg;
  padding-inline: $element-padding-md;
  max-height: 70vh;
  overflow-y: auto;

  h3 {
    @include text-md-bold;
    margin: $margin-null;
  }

  p {
    margin: $margin-null;
    @include text-xs-reg;
  }

  .report-options {
    display: flex;
    flex-direction: column;
    gap: $spacing-small-sm;

    .custom-filters {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: $spacing-small-lg;

      .custom-filters-options-check-input {
        display: flex;
        flex-direction: column;
        gap: $spacing-small-xs;
      }

      button {
        padding: $element-padding-xs $element-padding-md;
        @include text-sm-bold;
      }
    }
  }
}