@use 'styles/abstracts' as *;

.user-groups-settings-header-wrapper {
  display: flex;
  align-items: center;
  gap: $spacing-small-md;
}

.user-group-no-results {
  height: 100%;
  padding: $margin-small-md;
}

.user-groups-container {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  margin: $margin-small-xs;
}

.user-group-user-container {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}