$main: #166FEC;
$mainLight: #2584F4;
$mainOpacity20: rgba($main, 0.2);

$dark: #12161D;
$lessDark: #262B34;
$gray: #6F7278;
$lightGray: #979AA0;
$lighterGray: #CED1D7;
$lightestGray: #EFF1F5;
$offWhite: #FAFAFB;
$white: #FFFFFF;

$borderLine1: rgba(#93A0BB, 0.18);

$error: #DE311E;
$warning: #FFCF52;
$success: #22C799;

$blue: #89A2F7;
$blue2: #94C7ED;
$orange: #EB9A6D;
$red: #F47B7B;
$red2: #C65C5C;
$pink: #FF9BB9;
$green: #92B985;
$green2: #BFDFC6;
$purple: #9D86DC;
$purple2: #BDAEE8;
$yellow: #E4B555;
$yellow2: #EDCD8D;

$transparent: inherit;