@use '@reply-pro/component-library/abstracts' as *;

.location-permissions-modal {
    overflow: hidden;

    .modal-body {
        padding: $padding-null !important;

        .user-location-permissions-modal-body {
            max-height: 300px;
            overflow: auto;
            .location-group-container {
                .location-group {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: $element-padding-lg;
                    cursor: pointer;
                    color: $gray;
                    text-transform: uppercase;
                    border-top: $border-skinny-default;

                    &:first-child {
                        border-width: 0;
                    }

                    .location-group-name {
                        @include heading-petite;
                    }

                    .type {
                        @include text-xxs-petite;
                    }

                    &:hover {
                        background-color: $offWhite;
                    }

                    .location-group-details {
                        display: flex;
                        align-items: center;
                        gap: $spacing-small-xs;

                        svg {
                            width: 18px;
                        }
                    }
                }
            }
        }
        .no-location-permissions {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            color: $gray;
            padding: $element-padding-lg;
            gap: $spacing-small-lg;
            .astronaut-empty-state {
                width: 20%;
            }
        }
    }
}