@use '@reply-pro/component-library/abstracts' as *;

.add-custom-filters {
  width: 1000px;
  
  .metabase-filters-body {
    display: flex;
    flex-direction: column;
    gap: $spacing-small-sm;
    border: $border-skinny-default;
    padding-block: $element-padding-sm;
    padding-inline: $element-padding-xs;
    border-radius: $border-radius-sm;
    
    .metabase-selected-filters {
      min-height: 200px;
      max-height: 40vh;
      overflow: auto;
      padding-inline: $element-padding-sm;
  
      &:has(.metabase-no-filters) {
        display: flex;
      }
  
      .metabase-no-filters {
        color: $gray;
        margin: auto;
        display: grid;
        place-content: center;
        gap: $spacing-small-sm;

        button {
          margin: auto
        }
      }
  
      .metabase-selected-filter {
        display: flex;
        gap: $spacing-small-xs;
        justify-content: space-between;
        align-items: center;
        border-bottom: $border-skinny-default;
        
        &:last-child {
          border-bottom: $border-null;
        }
  
        span {
          flex: 1 1 0;
          padding: $element-padding-sm;
          min-width: 0;
  
          &:nth-child(1) {
            font-weight: bold;
            max-width: 300px;
          }
  
          &:nth-child(2) {
            font-weight: 500;
          }
        }
  
        button {
          height: fit-content;
          background: inherit;
          border: $border-skinny-default;
          border-radius: 50%;
          padding: $element-padding-xxs;
          width: 24px;
          height: 24px;
          display: flex;
          color: $gray;
          cursor: pointer;
          
          &:hover {
            background-color: $lightestGray;
            color: $dark;
          }
          
          svg {
            margin: auto;
            flex-grow: 1;
          }
        }
      }
    }
    
    .metabase-filters-add {
      display: flex;
      gap: $spacing-small-xs;
      justify-content: space-between;
      padding-inline: $element-padding-sm;
    
      .metabase-filters-select-input {
        flex: 1 1 0;
  
        &:focus-within {
          border-color: $gray !important;
        }
      }
    
      .metabase-filters-add-button {
        margin: auto;
      }
    }
  }
}

.metabase-key-option {
  display: flex;
  align-items: center;
  gap: $spacing-small-xxs;

  span {
    display: flex;
    align-items: center;

    .pencil-icon {
      height: 12px;
      width: 12px;
      flex-basis: 12px;
      color: $gray;
    }
  }
}
