@use '@reply-pro/component-library/abstracts' as *;

.interaction-tag {
  display: flex;
  flex-direction: column;
  gap: $spacing-small-md;
}

.modal-body{
    .tags-editor {
      box-sizing: border-box;
      width: 100%;
      height: calc(100vh - 84px);
      border-radius: $border-radius-lg;
      background-color: $white;
      border: $border-skinny-default;
      overflow-y: auto;
      padding-top: $element-padding-lg;
      margin-bottom: $element-padding-lg;
  
      .tags {
        width: 100%;
      }
    }
}