@use '@reply-pro/component-library/abstracts' as *;

@mixin truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.right-top-elements {
  display: flex;
  align-items: center;
  gap: $spacing-small-xs;

  .reload-icon {
    margin: $margin-null;

    &.rotate {
      animation: pulseRotate 1s infinite;
    }
  }
}

.manage-accounts-modal {
  width: 900px;
}

.manage-accounts-loading-spinner-container {
  height: 100%;
  display: grid;
  place-content: center;
}

.account-nav-item {
  padding: $element-padding-lg !important;
  cursor: auto !important;

  &:first-child {
    border-top: $border-null;
  }
}

.account-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-small-md;
  width: 100%;

  .account-details {
    display: flex;
    flex-direction: column;
    gap: $spacing-small-xs;
    max-width: 200px;

    .account-name {
      @include heading-petite;
      @include truncate-text();
    }

    .account-id-and-buttons {

      align-items: center;

      .account-id-container {
        display: flex;
        align-items: center;
        gap: $spacing-small-md;

        .account-id {
          @include truncate-text;
          @include text-xs-reg;
          color: $gray;
        }

        .clipboard {
          width: 12px;
          cursor: pointer;
        }
      }

      .api-key-container {
        display: flex;
        align-items: center;
        gap: $spacing-small-md;
      
        .api-key {
          @include truncate-text;
          @include text-xs-reg;
          color: $gray;
        }
      
        .clipboard {
          width: 12px;
          cursor: pointer;
        }
      }
    }
  }

  .account-actions {
    .features-container {
      display: flex;
      justify-content: flex-end !important;
      gap: $spacing-small-xs;
      align-items: center !important;
      width: 100%;

      .feature-container {
        padding: $element-padding-xs $element-padding-sm;
        border-radius: $border-radius-md;
        color: $gray;
        display: flex;
        align-items: center;
        gap: $spacing-small-xxs;

        .feature-name {
          @include text-sm-reg;
        }

        .feature-icon-container {
          display: flex;
          align-items: center;

          .feature-icon {
            width: 18px;
            margin: $margin-null;
          }
        }

        &.not-allowed {
          cursor: not-allowed;
        }

        &.disabled {
          background: #eff1f5;
          border-color: #eff1f5;

          .feature-name {
            color: #979aa0;
          }

          .feature-icon-container {
            .feature-icon {
              color: #979aa0;
            }
          }

          cursor: pointer;
        }
      }
    }

    .round-buttons {
      display: flex;
      justify-content: flex-end !important;

      .toggle-notifications-button, .select-account-button {
        padding: $element-padding-xs;
        border-radius: $border-radius-circle;

        .button-icon {
          margin: $margin-null;
        }
      }

    }
  }
}

@keyframes pulseRotate {
  100% {
    transform: rotate(.5turn)
  }
}
