@use '@reply-pro/component-library/abstracts' as *;

.atronaut-empty-state-container {
  margin: auto;
  height: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-small-xl;
  padding: $element-padding-md;
  height: 75vh;

  .astronaut-image {
    display: flex;
    flex-direction: column;
    justify-self: center;
    width: 70%;
  }

  .text {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: $gray;
    @include text-sm-reg;
  }

  .instructions {
    width: 60%;
  }
}