@use 'styles/abstracts' as *;

.users-header {
  display: flex;
  justify-content: space-between;

  .title {
    display: flex;
    flex-direction: row;
    gap: $spacing-small-md;
    align-items: center;

    .count {
      align-items: center;
      display: flex;
      gap: $spacing-small-sm;
      height: 18px;

      .clear {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: $spacing-small-xxs;
        color: $error;
        
        svg {
        height: 18px;
        }
      }
    }
  }
}