$spacing-null: 0rem;          // 0px
$spacing-small-xxs: 0.25rem;  // 4px
$spacing-small-xs: 0.5rem;    // 8px
$spacing-small-sm: 0.75rem;   // 12px
$spacing-small-md: 1rem;      // 16px
$spacing-small-lg: 1.5rem;    // 24px
$spacing-small-xl: 2rem;      // 32px
$spacing-small-xxl: 3rem;     // 48px
$spacing-small-xxxl: 4rem;     // 64px

$spacing-large-sm: 5rem;      // 80px
$spacing-large-md: 8rem;      // 128px
$spacing-large-lg: 16rem;     // 256px