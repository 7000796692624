@use 'styles/abstracts' as *;

.settings-title {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: $margin-small-sm;

  .icon-title {
    margin-block: auto;
    padding: 3px;
    width: 30px;
    height: 30px;
    color: $main;
    background-color: $mainOpacity20;
    border-radius: 50%;
  }
}

