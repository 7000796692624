@use '@reply-pro/component-library/abstracts' as *;

.survey-themes-container, .survey-theme {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    gap: $spacing-small-md;
}

.survey-themes-container {
    border-top: $border-skinny-default;
    padding: $element-padding-lg;
    width: 100%;
    .survey-theme {
        padding: $element-padding-md;
        background-color: $offWhite;
        border-radius: $border-radius-md;
        cursor: pointer;
        text-align: center;
        @include heading-petite;
        text-transform: uppercase;
        color: $gray;
        border: $border-fat-default;
        img {
            border-radius: $border-radius-sm;
            width: 100%;
        }
        &:hover {
            background-color: $lightestGray;
        }
        &.selected {
            background-color: $mainLight;
            color: $white;
            border-color: transparent;
        }
    }
}
