@use '@reply-pro/component-library/abstracts' as *;

.reorder-report-page-icon {
    color: $gray;

    &:hover {
        color: $dark;
    }

    &.save {
        color: $mainLight;
    }
}

.section-options {
    display: flex;
    gap: $spacing-small-lg;

    .add-report-page-icon {
        color: $main;
    }
}
