@use '@reply-pro/component-library/abstracts' as *;

.location-manager-modal {
  justify-content: flex-start;

  .modal-body{
    border: 0px;
    padding: $padding-null $element-padding-lg;
  }
  .location-manager-modal-title {
    display: flex;
    gap: $spacing-small-xs;
    margin: $margin-null $margin-null $element-margin-md $margin-null;
    align-items: center;
    width: 100%;

    .icon {
      svg {
        width: 28px;
        color: $gray;
      }
    }

    .name {
      display: flex;
      align-items: center;
      width: 100%;

      .arrow-left,
      .arrow-right {
        display: flex;
        align-items: center;
        width: 24px;
        cursor: pointer;
        color: $gray;
        transition: 400ms;

        &.disabled {
          color: $lighterGray;
          cursor: default;
          transition: 400ms;
        }
      }

      h1 {
        margin: $margin-null $element-margin-xxs;
        color: $lessDark;
        @include heading-sm;
      }
    }
  }
}

@media (max-width: $mobileMaxWidth) {
  .location-manager-modal {
    .location-manager-modal-title {
      .name {
        justify-content: center;
      }
    }
  }
}
