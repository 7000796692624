@use 'styles/abstracts' as *;

.not-found {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  
    h1 {
      font-size: 4rem;
      color: $dark;
      text-align: center;
      margin-bottom: 2rem;
    }
  
    p {
      font-size: 1.6rem;
      color: $gray;
      text-align: center;
      margin-bottom: 1rem;
  
      span {
        color: $main;
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }
