@use '@reply-pro/component-library/abstracts' as *;


.client-webhooks-wrapper {
  .client-webhooks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .client-webhooks-details {
      display: flex;
      flex-direction: column;
      gap: $spacing-small-xs;

      .client-webhooks-details-name {
        @include heading-petite;
        text-transform: uppercase;
      }

      .client-webhooks-details-description {
        @include text-xs-reg;
        color: $gray;
      }
    }

    .client-webhooks-details-button {
      svg {
        width: 18px;
        height: 18px;
        color: $gray;
      }
    }
  }
}