@use "styles/abstracts" as *;

.manage-accounts-wrap {
  .select-account-container {
    width: 100%;
    .select-account {
      width: 100%;
    }
  }

  .manage-accounts-button-container {
    padding-top: $padding-small-md;
    .manage-accounts-button {
      width: 100%;
      .reporting-warning {
        color: $error;
      }
    }
  }
}
