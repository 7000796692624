@use '@reply-pro/component-library/abstracts' as *;

.sections-list {
  flex-direction: column;
  height: calc(100vh - 200px);
  overflow: auto;

  .sortable-item.first-element {
    .section-listing {
      color: $gray;
      border-top: $border-null !important;
    }
  }

  .section-listing {
    color: $gray;
  }
}
