@use '@reply-pro/component-library/abstracts' as *;

.confirmation-modal {
    max-width: 500px;
    .modal-header, .modal-body {
        min-width: 100%;
        max-width: 100%;
    }
}

.confirmation-modal-body {
    display: flex;
    flex-direction: column;
    gap: $spacing-small-md;
    padding: $element-padding-lg;
    text-align: left;
    p {
        @include text-sm-med;
        strong {
            @include text-sm-bold;
        }
    }
}