@use 'styles/abstracts' as *;

.collection-detail { 
  flex-grow: 1;
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  
  .collection-detail-header {
    display: flex;
    padding: $padding-small-lg;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid rgba(147, 160, 187, 0.24);

    .report-sections-actions {
      display: flex;
      align-items: center;
      width: fit-content;
      justify-content: flex-end;
      gap: $spacing-small-sm;
    }
  }
}
