@use '@reply-pro/component-library/abstracts' as *;

.theme-upload-modal, .theme-upload-modal {
    min-width: 480px !important;
    width: 480px;
}

.file-and-instructions-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: $spacing-small-xs;
    justify-content: center;
    padding: $element-padding-xs;
    .instructions-container {
        display: flex;
        flex-direction: column;
        gap: $spacing-small-xxs;
        text-wrap: nowrap;
        span {
            @include text-sm-reg;
            color: $gray;
            &.warning {
                color: $red2;
            }
        }
    }
    .theme-manager-container {
        display: flex;
        flex-direction: column;
        gap: $spacing-small-xs;
        width: 100%;
        justify-content: center;
        position: relative;

        .theme-file-selector {
            display: flex;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            background-color: $white;
            padding: $element-padding-sm $element-padding-md;
            border: $border-skinny-default;
            border-radius: $border-radius-xs;
            transition: 0.2s;
            @include text-xs-reg;

            span {
                display: flex;
                gap: $spacing-small-xxs;
                color: $gray;

                svg {
                    width: 14px;
                    color: $gray;
                }
            }

            &:hover {
                background-color: $offWhite;

                span {
                    color: $dark;
                }

                transition: 0.2s;
            }

            span {
                pointer-events: none;
            }

            input[type='file'] {
                position: absolute;
                top: 0;
                right: 0;
                margin: 0;
                padding: 0;
                font-size: 100px;
                cursor: pointer;
                opacity: 0;
            }

            &.success {
                border-color: $completed;

                span {
                    svg {
                        color: $completed;
                    }
                }
            }
        }

        .no-file-name {
            @include text-sm-reg;
            color: $gray;
        }
    }
}
