@use '@reply-pro/component-library/abstracts' as *;

.in-app-components-show-room {
  flex-grow: 1;
  border: $border-skinny-default;
  border-radius: $border-radius-xl;
  padding-block: $element-padding-xl;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .top-components {
      display: flex;
      justify-content: center;
      padding-inline: $element-padding-xxl;
      border-bottom: $border-skinny-default;
      padding-bottom: calc($spacing-small-xxl / 2);
      gap: $spacing-small-xl;
      flex-grow: 1;
      align-items: center;

      .logo {
          width: 40%;
          flex-shrink: 1;
      }
      
      .gray-logo {
        width: 25%;
        filter: grayscale(100%);
      }

      .brand-logo {
        height: 100px;
        max-width: 40%;
      }
      
      .brand-gray-logo {
        height: 75px;
        max-width: 25%;
        filter: grayscale(100%);
      }
  }
  
  .inbox {
      display: flex;
      justify-content: space-around;
      gap: $spacing-small-xl;
      padding-inline: $spacing-small-xl;
      padding-block: calc($spacing-small-xxl / 2);
      border-bottom: $border-skinny-default;
      flex-grow: 1;
      
      .interactions {
          width: 70%;
          border: $border-skinny-default;
          
          .interaction {
              flex-shrink: 0;
              width: 100%;
              flex-grow: 1;
          }
      }
      
      .action {
          display: block;
          width: 30vw;
          
          div {
              max-width: 100%;
          }
      }
  }
  
  .bottom-components {
      display: flex;
      justify-content: space-around;
      gap: $spacing-small-md;
      padding-top: calc($spacing-small-xxl / 2);
      padding-inline: $spacing-small-xl;
      flex-grow: 1;

      .multi-action-button, .single-action-button {
          margin-block: auto;
      }

      .nav-bar {
          margin-block: auto;
          height: fit-content;
      }
  }
}