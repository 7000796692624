@use '@reply-pro/component-library/abstracts' as *;

.user-manager-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $spacing-small-xs;
  width: 100%;
  text-align: center;
  height: 110px;
  .format-warning {
    @include text-sm-reg;
    color: $red;
  }
  .reset-password-input {
    width: 270px;
  }
}

.user-manager-modal-actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}