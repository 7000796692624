@use '@reply-pro/component-library/abstracts' as *;

.purchase-and-ticket-container {
    width: 100%;
    .metadata-container {
        display: flex;
        flex-direction: column;
        gap: $spacing-small-xs;
        width: 100%;
        .selector {
            width: 100%;
        }
    }
}
