@use '@reply-pro/component-library/abstracts' as *;

.location-parent {
  display: flex;
  align-items: center;
  color: $main;
  gap: $spacing-small-xxs;
  cursor: pointer;

  span {
    @include text-xs-bold;
  }
  .parent-icon {
    width: 18px;
  }
}
