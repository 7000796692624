@use 'styles/abstracts' as *;

.user-group-modal {
  .modal-header, .modal-body {
    width: 500px !important;
    min-width: 0 !important;
  }

  .add-user-group-actions {
    display: flex;
    flex-direction: column;
    text-align: right;
    gap: $spacing-small-xxs;

    .error-message {
      @include text-xs-reg;
      color: $red2;
    }
  }

  .user-group-modal-body {
    display: flex;
    flex-direction: column;
    gap: $spacing-small-md;
    padding-block: $padding-small-xs;
    width: 100%;
    margin: auto;

    .user-group-input-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: $spacing-small-xxs;

      .user-group-input {
        width: 100%;
      }

      span {
        color: $red2;
      }

      h4 {
        @include text-sm-bold;
      }
    
      textarea {
        resize: none;
      }
  
      .helper-text {
        @include text-xs-reg;
        color: $gray;
      }
    }


  }
}