@use '@reply-pro/component-library/abstracts' as *;

.auto-respond-attributes {

  .modal-body {
    border: $border-null;
    justify-content: flex-start;
    gap: $spacing-small-xl;
    display: flex;
    flex-grow: 1;

    .auto-respond-attributes-container {
      display: flex;
      flex-direction: column;
      gap: $spacing-small-sm;
      background-color: $white;
      padding: $element-padding-lg;
      border: $border-skinny-default;
      border-radius: $border-radius-sm;
      height: 100%;

      .selector {
        width: 100%;
      }

      .channel-selector {
        display: flex;
        flex-direction: column;
        gap: $spacing-small-xs;
        border-bottom: $border-skinny-default;
        span {
          @include text-sm-med;
        }
        .channel-selector-container {
          padding-bottom: $element-padding-md;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: $spacing-small-md;
      }
        .select-button {
          display: flex;
          justify-content: center;
          color: $gray !important;
          background-color: $lightestGray !important;
          border-radius: $border-radius-lg !important;

          &.active {
            border-color: $main !important;
            color: $main !important;
            background-color: $offBlue !important;
          }
        }
      }

      .metadata-container {
        display: flex;
        flex-direction: column;
        gap: $spacing-small-xxs;
        span {
          @include text-sm-med;
        }
      }

      .composer {
        width: 100%;
      }

      .composer-text-area {
        width: 100%;
        border-radius: $border-radius-md;
        padding: $element-padding-md;
        background-color: $transparent;

        textarea {
          resize: none;
        }
      }
    }
  }




}