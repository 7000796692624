@use '@reply-pro/component-library/abstracts' as *;

.post-survey-actions-modal {
    .modal-body {
        min-width: 600px;
        max-width: 700px;

        .post-survey-actions-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            border: $border-skinny-default;
            border-radius: $border-radius-sm;
            height: 280px;
            overflow: auto;

            .loading-container, .no-post-survey-actions {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }

            .post-survey-action {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: $element-padding-lg;
                border-top: $border-skinny-default;

                &:first-child {
                    border-top: $border-null;
                }

                .post-actions-details {
                    display: flex;
                    flex-direction: column;
                    gap: $spacing-small-xs;
                    width: 100%;
                    .question {
                        @include text-sm-med;
                    }
                    .answer, .post-action {
                        @include text-sm-reg;
                    }
                    .post-action {
                        color: $gray;
                    }
                }
                .action-remove-container{
                    svg {
                        width: 16px;
                        cursor: pointer;
                        &:hover {
                            color: $red2;
                        }
                    }
                }
            }

            .no-post-survey-actions {
                color: $gray;
            }
        }
    }
}