@use 'styles/abstracts' as *;

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  padding: $padding-small-lg;
}

.login-wrapper {
  width: 200px;

  & > div:not(.image-wrapper) {
    margin-bottom: $margin-small-xs;
  }
}

.image-wrapper {
  text-align: center;
  margin-bottom: $margin-small-md;

  img {
    max-width: 100%;
  }
}

.login-input {
  width: 100%;
}

.button-wrapper:last-child {
  padding-bottom: $padding-small-xxl;
}

.login-button {
  width: 100%;
}

.error {
  color: $error;
  text-align: center;
  margin-top: $margin-small-md;
}
